<template>
  <div class="app-layout">
    <nav class="bg-white">
      <app-page-wrapper class="flex h-20 items-center justify-between">
        <nuxt-link
          class="flex items-center gap-2 md:gap-4"
          to="/partnerships/act-for-impact"
        >
          <app-image
            alt="Pollen logo"
            class="w-[125px] md:w-[170px]"
            src="/images/logo_full_color.svg"
            :width="170"
          />

          <app-divider class="!h-8" vertical />

          <app-image
            alt="Act for Impact logo"
            class="!h-6 md:!h-10"
            :height="40"
            src="/images/logos/act_for_impact.svg"
          />
        </nuxt-link>

        <main-header-user-menu no-organization-logo />
      </app-page-wrapper>
    </nav>

    <main>
      <slot />
    </main>

    <div class="hidden h-20 bg-primary-50 md:block" />

    <app-page-wrapper class="grid gap-20 py-20">
      <act-for-impact-courses :exclude-course-slug="courseSlug" />
      <act-for-impact-about />
    </app-page-wrapper>

    <main-footer />
  </div>
</template>

<script setup lang="ts">
import MainHeaderUserMenu from "~/layouts/_includes/components/main-header-user-menu.vue";
import MainFooter from "~/layouts/_includes/main-footer.vue";

const courseSlug = useRoute("courses-slug").params.slug;
</script>

<style scoped>
.app-layout {
  --layout-offset-top: 0px;
}
</style>
